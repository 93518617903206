<template>
    <div class="animated fadeIn">
        <CCard>
            <CCardHeader><font-awesome-icon icon="share-from-square"/> Document Template Shared
            </CCardHeader>

            <CCardBody>
                <CDataTable
                    :items="sorted_dt_shared_list"
                    :fields="dt_shared_field"
                    :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                    :items-per-page="10"
                    border
                    items-per-page-select
                    sorter
                    pagination
                    :loading="this.table_loading"
                >
                    <template slot="no-items-view" v-if="this.table_loading">
                        <center>
                            <CSpinner
                                style="width:4rem;height:4rem;"
                                color="info"
                            />
                        </center>
                    </template>
                    <template #name="{item}">
                        <td>
                            {{ item.name }}
                        </td>
                    </template>
                    <template #summary="{item}">
                        <td>
                            <CButton @click="$refs.dtSharedModal.dt_shared_modal = true, emitDtSharedModal(item.id, item.name)"
                                    pressed
                                    aria-pressed="true"
                                    variant="ghost"
                                    class="btn-summary"
                                    size="sm"
                                    title="Number of shared document template(s) this user belong."
                                    color="dark" 
                                >
                                    Shared to {{ item.dt_shared_count }} document template/s
                            </CButton>
                        </td>
                    </template>
                    
                    <template #Action="{item}">
                        <td>
                            <!-- <router-link :to="{ path: `/${$store.getters['getOrganizationName']}/drs/document-template-shared/${item.id}`, query: {id:`${item.id}`,name: `${item.name}`, shared: item.dt_shared_count} }">
                                <CButton color="info" shape="pill">
                                <font-awesome-icon icon="cog"/> Manage
                                </CButton>
                            </router-link> -->
                            <router-link :to="{ path: `/${$store.getters['getOrganizationName']}/drs/document-template-shared/${paramEncoder(item.id)}`, query: {name:`${item.name}`, shared: paramEncoder(item.dt_shared_count)} }">
                                <CButton color="info" shape="pill" size="sm">
                                <font-awesome-icon icon="cog"/> Manage
                                </CButton>
                            </router-link>
                            
                        </td>
                    </template>
                </CDataTable>
            </CCardBody>
        </CCard>
        <DocumentTemplateSharedModal ref="dtSharedModal" />
    </div>

</template>

<script>
import DocumentTemplateSharedModal from '../../modals/DTSharedModal'
export default{
    name : 'DocumentTemplateShared',
    components : {
        DocumentTemplateSharedModal
    },

    data() {
        return {
            table_loading: true,
            dt_shared_list  : [],
        }
    },

    created(){
        this.getDTShared();
        this.$emit('activeTab', 0);
    },

    computed : {
        dt_shared_field: function () {
            let custom_fields= [
                { key: 'name', label: 'Name'},
                { key: 'summary'},
                // { key: 'status' },
            ]
            if(this.$store.getters.can('view-document-template-shared')) {
                custom_fields.push({ key: 'Action', sorter: false})
            }
            return custom_fields;
        },
        sorted_dt_shared_list () {
            return this.dt_shared_list.sort((a,b) => a.name.localeCompare(b.name));
        }
    },
    watch : {},
    methods : {

        getDTShared : function () {
            this.$Progress.start()
            axios.get('drs/document-template-shared/list', {validateStatus: ()=>true})
                .then( response => {
                    this.dt_shared_list = response.data.data;
                    this.table_loading = false;
                    this.$Progress.finish()
                });
        },

        emitDtSharedModal: function (shared_to_user_id, name) {
            this.$emit('show_dt_shared_modal', shared_to_user_id , name);
        }, 
    }
}
</script>

