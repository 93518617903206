<template>
    <CModal  :show.sync=" dt_shared_modal"
        color="primary"
        size="lg"
        :closeOnBackdrop="false">
        <template #header>
            <h5> <font-awesome-icon icon="share-from-square"/> {{selected_user.name + '\'s Shared Document Template/s'}}</h5>
            <CButton 
                class="pull-right" 
                color="light" 
                shape="pill" 
                size="sm"
                @click=" dt_shared_modal = false"
            >
                <font-awesome-icon icon="times"/>
            </CButton>
        </template>

        <template #body-wrapper>
            <CCardBody>
                <CDataTable
                    :items="sorted_templates"
                    :fields="document_templates_fields"
                    :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                    :items-per-page="10"
                    items-per-page-select
                    border
                    sorter
                    pagination
                    :loading="table_loading"
                    size="sm"
                >
                </CDataTable>
            </CCardBody>
        </template>
        <template #footer>
            <hr hide>
        </template>
    </CModal>
</template>


<script>
import axios from 'axios';
export default {
    name : 'DocumentTemplateSharedModal',
    props : {
        shared_to_user_id : Number,
    },
    data(){
        return {
            dt_shared_modal: false,
            table_loading: true,
            document_templates: [],
            document_templates_fields: [
                {key: 'name', label: 'Name'},
                {key: 'category_name', label: 'Category Name'},
                // {key: 'description', label: 'Description'},
            ],
            selected_user : {
                name : '',
                head_id : ''
            },
        }
    },
    created(){
        this.$parent.$on('show_dt_shared_modal', this.getDocumentTemplates);
    },
    computed : {
        sorted_templates : function () {
            return this.document_templates.sort((a,b) => a.name.localeCompare(b.name));
        }
    },
    methods: {
         /**
         * This getDocumentTemplates function returns list of shared document
         * 
         * @param object parameter
         * The object has default value of null.
         * 
         * parameter: {
         *      document_template: (id) 
         *      name: (string)
         *      category_name: (string)
         *      description: (string)
         * }
         * 
         * @return the required data
         */
        getDocumentTemplates : function(shared_to_user_id=null , name) {
            
            this.$Progress.start()
            this.table_loading=true;
            const requestData = {
                shared_to_user_id: this.paramEncoder(shared_to_user_id)
            };
            this.selected_user.head_id = shared_to_user_id;
            this.selected_user.name = name;
            if(this.document_templates.length > 0){
                this.document_templates = [];
            }
            axios.post('/drs/document-template-shared/dt-list-with-parameter', requestData, {validateStatus: () => true})
            .then(response => {
                if(response.status==200){
                    this.document_templates = [];
                    for(let i=0; i<response.data.data.length; i++){
                        let dt = response.data.data[i].document_template;
                        if(dt != null) {
                            let option = {
                            document_template_id : dt.id,
                            name : dt.name,
                            category_name : dt.category_name,
                            // description : dt.description
                            }
                            if(dt.deleted_at == null) {
                                this.document_templates.push(option);
                            }
                        }
                    }
                    this.table_loading = false
                    this.$Progress.finish()
                }
            })
        },
    },
    watch: {}
}
</script>